<template>
  <div class="policy" v-html="data">
  </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue';
import { marked } from 'marked';

export default {
  setup() {
    const data = ref(null);

    onBeforeMount(() => {
      const file = import('../assets/md/privacypolicy.md');
      file.then(
        (fileData) => {
          data.value = marked(fileData.default);
        },
        () => {
          data.value = 'Error! cant open .md file!';
        },
      );
    });

    return { data };
  },
};
</script>

<style lang="scss">
  @import '../styles/scss/policy.scss';
</style>
